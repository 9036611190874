.caption {
    margin-right: 10px;
    padding: 0;
    width: 80%;
}

.captionBody {
    display: flex;
    min-width: 250px;
}

.captionElementMonth {
    width: 165px;
    &:first-of-type {
        margin-right: 10px;
    }
}

.captionElementYear {
    width: 120px;
}

.captionSelectList {
    max-height: 270px;
    overflow: auto;
    li {
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 32px;
        font-size: 13px;
    }
}

.captionSelected {
    padding: 0 10px;
    padding-right: 26px;
    height: 32px;
    font-size: 13px;
    &::after {
        right: 10px;
        margin: 0;
    }
}

.wrapper {
    position: relative;
    width: 100%;
    height: 38px;
}

.navbar {
    position: absolute;
    top: calc(1em + 2px);
    right: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(20% - 1em);
    height: 32px;
    button {
        margin: 0;
        padding: 0;
        background: none;
        border: none;
    }
}

.dayPicker {
    position: absolute;
    z-index: 9;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.errorField {
    margin: 5px 0 0 0;
    color: #f00;
    font-size: 12px;
}